<template>
  <transition name='el-fade-in-linear' v-if="isInitLoading">
    <div class="InitLoadingBox">
      <div class="InitLoading">
        <img :src="loadingImg">
        页面进入中..
      </div>
    </div>
  </transition>
</template>

<script>
import loadingImg from '@/assets/icons/lbLoading.png'

export default {
  name: 'InitLoading',
  data() {
    return {
      loadingImg: loadingImg
    }
  },
  props: {
    isInitLoading: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.$emit('update:isInitLoading', false)
    }, 2000)
  }
}
</script>

<style lang="scss" scoped>
.InitLoadingBox {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99;

  .InitLoading {
    position: absolute;
    z-index: 99;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 500px;
    height: 100px;
    text-align: center;

    > img {
      width: 40px;
      height: 40px;
      vertical-align: middle;
      animation: change 3s;
      animation-iteration-count: infinite;
      margin-right: 10px;
    }


    --bg-size: 400%;
    --color-one: rgb(73, 47, 93);
    --color-two: #ff3176;
    font-size: clamp(2rem, 25vmin, 2rem);
    background: linear-gradient(
            90deg,
            var(--color-one),
            var(--color-two),
            var(--color-one)
    ) 0 0 / var(--bg-size) 100%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: move-bg 8s infinite linear;

  }
}


@media (prefers-reduced-motion: no-preference) {
  .boujee-text {
    animation: move-bg 8s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

@keyframes change {
  0% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

</style>
